$(() => {
  new TachoVehicleActivityBehaviour($('#tacho_vehicle_activity'))
});

class TachoVehicleActivityBehaviour
{
  #$table

  constructor ($table) {
    this.#$table = $table
    this.#attachBehaviours()
  }

  #attachBehaviours()
  {
    const $table = this.#$table;

    app.DOM.form.find('#toggle_cumulative').on('click', function() {

      // change button state
      $(this).find('span.instance').toggleClass('hidden');
      $(this).find('span.cumulative').toggleClass('hidden');
      // change table view
      $table.find('span.instance').toggleClass('hidden');
      $table.find('span.cumulative').toggleClass('hidden');
    })

    app.DOM.form.find('#toggle_tails').on('click', function() {

      // change button state
      $(this).toggleClass('button-blue button-red');
      $(this).find('span.show_tails').toggleClass('hidden');
      $(this).find('span.hide_tails').toggleClass('hidden');

      // change table view
      $table.find('span.show_tails').toggleClass('hidden');
      $table.find('span.hide_tails').toggleClass('hidden');
      $table.find('tr.tails').toggleClass('hidden');
    });

    if( $table.find('tr.Secondary').length === 0 ) {
      app.DOM.form.find('#slot-container').addClass('hidden');
      return;
    }

    app.DOM.form.find('#slot-container input').click(this.#toggleEventsFromSlots.bind(this));
  }

  #toggleEventsFromSlots(event)
  {
    const cssClass = event.currentTarget.value;

    console.log(cssClass);

    const $rowsToChange = this.#$table.find(`tr.${cssClass}`)

    if (event.currentTarget.checked) {
      $rowsToChange.removeClass('hidden');
    } else {
      $rowsToChange.addClass('hidden');
    }
  }
}